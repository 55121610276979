.thank_umain {
    background-image: url(../../../assets/images/thankubackgrnd.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 0px;
    height: 100vh;

}

.thanku_img {
    text-align: center;
    padding: 27px 0px;
}

.thanku_booking {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 31px;
    /* line-height: 62px; */
    color: #01BFA5;
    margin-bottom: 20px;
}

.thanku_content {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 19px;
    line-height: 19px;
    text-align: center;
    color: #053469;
    padding-bottom: 36px;
}

.thanku_leftimg {
    text-align: center;
    background: #ffffff;
    border: 1px solid #636E72;
    box-shadow: 0px 0px 10px rgb(0 108 207 / 10%);
    border-radius: 7px;
    padding: 48px 20px;
}

.thanku_content a {
    color: #01BFA5;
}

.thanku_cont li {
    float: left;
    width: 50%;
    list-style-type: none;
    padding: 6px 0px;
}

.thanku_questions {
    text-align: center;
    padding: 40px 0px 40px 0px;
    color: #002C60;
    font-weight: 500;
}

.thanku_row {
    padding: 10px 15px;
}

.thanku_right {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #2D3436;
    background: #ffffff;
    border: 1px solid #636E72;
    box-shadow: 0px 0px 10px rgb(0 108 207 / 10%);
    border-radius: 7px;
    padding: 20px;
}

.thanku_cont li:nth-child(even) {
    text-align: right;
    font-weight: 700;
}

.thanku_cont li:nth-child(odd) {
    text-align: left;
}

.thanku_questions span {
    color: #01BFA5;
    text-decoration: underline;
}
@media (min-width: 320px) and (max-width: 479px) {
    .thanku_leftimg {
        margin-bottom: 20px;
    }
}
@media (min-width: 480px) and (max-width: 639px) {
    .thanku_leftimg {
        margin-bottom: 20px;
    }
}
@media (min-width: 640px) and (max-width: 767px) {
    .thanku_row .col-sm-2 {
        display: none;
    }
    .thanku_row .col-sm-3, .thanku_row .col-sm-5 {
        width: 50%;
    }
}