@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Inter:wght@300;400;500&family=Montserrat:wght@300&family=Playfair+Display:wght@400;600&family=Poppins:wght@300;400&family=Roboto:wght@100;300&family=Rochester&family=Saira:wght@200;300;400&family=Teko:wght@300;400;500&family=Titillium+Web:wght@300;400&display=swap");

.booking_info {
  box-shadow: 0px 4px 20px rgba(0, 108, 207, 0.2);
  border-radius: 2px;
  padding: 20px 15px;
}
.booking_box {
  background-image: url("../../../assets/images/booking-info-bg.png");
  background-repeat: repeat;
  background-position: center;
}
.personal_info {
  box-shadow: 0px 4px 20px rgba(0, 108, 207, 0.2);
  border-radius: 2px;
}

.your_booking {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #000000;
  margin-bottom: 20px;
}

.main_booking {
  padding: 0px 30px;
}

.room {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #2d3436;
  margin-bottom: 12px;
  text-transform: capitalize;
}

.basic_details {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-size: 16px;
  color: #434343;
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;
}

.basic_price {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #053469;
  margin-bottom: 8px;
}

.guest_cont {
  width: 60%;
  font-weight: 600;
}

.guest_info {
  width: 40%;
  text-align: right;
  font-weight: 700;
}

.guest_infos {
  width: 40%;
  text-align: right;
}

.room1_img img {
  width: 100%;
}

.payable_price {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  color: #002c60;
}

.grand_total {
  border-top: 1px solid #082e59;
  border-bottom: 1px solid #082e59;
  padding: 20px 0px;
  margin-top: 10px;
}

.enter_text {
  /* font-family: "Inter", sans-serif; */
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: #2d3436;
  margin-left: 20px;
}

.namedata {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #2d3436;
  margin-top: 14px;
}

.namedata1 {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-size: 14px;
  color: #2d3436;
  font-weight: 600;
  margin-top: 14px;
  display: flex;
}

.input_details input {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #212121;
  padding: 20px;
  outline: none;
  margin-top: 8px;
  width: 100%;
  height: 56px;
}

.input_details_login input {
  background: #ffffff;
  border: 1px solid #ffb94e;
  border-radius: 2px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #212121;
  padding: 20px 20px 20px 85px;
  outline: none;
  margin-top: 8px;
  width: 100%;
  height: 56px;
}
.note {
  display: flex;
  align-items: center;
}

.note button {
  background-color: rgb(212 158 44);
  box-shadow: 0px 0px 10px rgb(0 108 207 / 10%);
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  color: rgb(255, 255, 255);
  border: none;
  width: 100%;
  padding: 10px;
  text-align: center;
  color: #000000;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  outline: none;
  height: 56px;
}

.Verfie button {
  background-color: rgb(212 158 44);
  box-shadow: 0px 0px 10px rgb(0 108 207 / 10%);
  border-radius: 4px;
  border: none;
  color: rgb(255, 255, 255);
  width: 100%;
  padding: 10px;
  text-align: center;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  outline: none;
  height: 56px;
}

/* .Verfie {
    margin-top: 34px;
} */

.input_names {
  margin: 23px 0px 36px;
}

.input_otp_details input {
  background: #ffffff;
  border: 1px solid #2d3436;
  border-radius: 2px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #212121;
  padding: 20px;
  outline: none;
  margin-top: 8px;
  width: 18%;
  text-align: center;
  height: 56px;
}

.input_otp_details {
  justify-content: space-between;
}

.otp_data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timer {
  color: #636e72;
}

.resend {
  text-decoration: underline;
}

.rdtDays tfoot {
  /* pointer-events: none; */
  /* visibility: hidden; */
}
.cost_details {
  padding: 0px 20px;
}
.input_otp_details {
  margin-bottom: 35px;
}
.booking_left {
  /* background-color: rgb(235 244 255); */
  background-color: #d49e2c54;
  padding: 12px;
}

.cost_details {
  padding: 40px 20px 0px 20px;
}

.guest_box {
  padding: 16px !important;
  margin-top: 10px !important;
  background-image: url("../../../assets/images/guest-box-bg.png");
  background-repeat: repeat;
  background-position: center;
}
.guest_box li {
  list-style-type: none;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.guest_box li:last-child {
  margin-bottom: 0px;
}
.price_details_head {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: rgb(0 44 96);
  margin-bottom: 20px;
}
.price_details li {
  list-style-type: none;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  color: rgb(77 77 77);
  margin-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cancelation_policy {
  margin-bottom: 15px;

  font-weight: 700;
}

.cancelation_policy a,
.cancelation_policy a:hover {
  color: rgb(212 158 44);
}
@media (min-width: 320px) and (max-width: 479px) {
  .payable_price {
    font-size: 11px;
  }
}
@media (min-width: 480px) and (max-width: 639px) {
  .input_otp_details input {
    width: 14%;
    padding: 10px;
  }

  .namedata1 {
    font-size: 12px;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .room {
    font-size: 12px;
  }
  .booking_main .col-sm-5,
  .booking_main .col-sm-7 {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .booking_box {
    margin-bottom: 20px;
  }
  .guest_box li {
    font-size: 12px;
  }
  .cost_details {
    padding: 20px 0px 0px 0px;
  }
  .your_booking {
    font-size: 20px;
  }

  .basic_details {
    font-size: 10px;
  }

  .main_room_info {
    padding: 4px;
  }

  .basic_price {
    font-size: 10px;
  }

  .enter_text {
    font-size: 14px;
  }

  .namedata {
    font-size: 12px;
  }

  .input_details input {
    height: 40px;
  }

  .namedata1 {
    font-size: 8px;
  }

  .input_otp_details input {
    height: 40px;
    padding: 16px;
  }

  .note button {
    height: 40px;
    font-size: 10px;
  }

  .Verfie button {
    height: 40px;
    font-size: 10px;
  }
  .main_booking {
    padding: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .booking_main .col-sm-5,
  .booking_main .col-sm-7 {
    width: 100%;
  }
  .basic_details {
    font-size: 10px;
  }
  .basic_price {
    font-size: 10px;
  }
  .namedata {
    font-size: 12px;
  }
  .input_details input {
    height: 40px;
    font-size: 12px;
  }
  .input_details_login .login_ip span {
    padding: 5px 10px;
    font-size: 12px;
  }
  .input_details_login input {
    padding: 10px 10px 10px 60px;
  }
  .main_standerd_rooom {
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .booking_main .col-sm-5,
  .booking_main .col-sm-7 {
    width: 100%;
  }
  .namedata1 {
    font-size: 10px;
  }

  .input_details input {
    height: 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .input_otp_details input {
    padding: 0px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.invalid-mob {
  display: block !important;
}
