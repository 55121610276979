.view_room {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #03438d;
  background: #ffffff;
  margin: 0px 5px;
  border: 1px solid #cee5ff;
  box-shadow: 0px 4px 10px rgba(0, 108, 207, 0.1);
  border-radius: 4px;
  width: 15%;
  height: 35px;
}

/* .main_increment input{
    pointer-events: none;
} */
.main_view_room.flt {
  text-align: end;
}

.table_container {
  margin: 20px 0px;
}

/* table {
  border-collapse: collapse;
  width: 80%;
  
}

th,
td {
  border: 1px solid #d49e2c;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #d49e2c;
  color: white;
  font-weight: bold;
}
tr:nth-child(even) {
  background-color: #c0b0b0;
}

tr:nth-child(odd) {
  background-color: #f2f2f2;
} */

.table {
  border-collapse: collapse;
  margin: auto;
  text-align: center;
}

.table-head {
  background-color: #d49e2c;
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.table-row:nth-child(even) {
  background-color: #c0b0b0;
  font-size: 16px;
}

.table-row:nth-child(odd) {
  background-color: #f2f2f2;
  font-size: 16px;
}

.table-header,
.table-data {
  padding: 8px;
}

.table-header {
  background-color: #d49e2c !important;
}

.table-data {
  font-weight: normal;
}

.view_room img {
  padding: 5px;
}

.room_stnd.flt {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 26.7941px;
  line-height: 32px;
  color: #002c60;
  padding: 15px 12px;
}

.main_standerd_rooom.flt {
  margin: 10px 0px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(0 108 207 / 20%);
  border-radius: 2px;
}
.main_Standerd_room {
  border-radius: 2px;
  box-shadow: 0px 4px 20px rgb(0 108 207 / 20%);
  border-radius: 2px;
  margin: 10px 0px;
}
.view_white {
  display: none;
}

.view_room:hover .view_blue {
  display: none !important;
}

.view_room:hover .view_white {
  display: inline-block !important;
}

.view_room:hover {
  background-color: #03438d;
  color: #fff;
}

.right_view_room img {
  width: 100%;
  /* padding: 5px 5px; */
  /* height: 400px; */
  /* object-fit: cover; */
}

.main_view.flt {
  display: flex;
  padding: 10px 10px;
}

.right_view_room {
  /* display: flex;
     */
  width: 85%;
}

.left_view_room {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
  width: 15%;
}

.left_view_room li {
  list-style-type: none;
}

/* .left_view_room img {
    padding: 0px 0px 5px 5px !important;
   
} */

.left_view_room li:last-child {
  padding-bottom: 0px !important;
}

.left_view_room li:last-child span {
  display: block;
  position: relative;
  text-align: center;
  bottom: 50%;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff !important;
}

.left_view_room li img {
  width: 100%;
  /* height: 77px; */
}

element.style {
  text-decoration: none;
}

.last_img {
  position: relative;
}

.last_img span {
  position: absolute;
  top: 33%;
  right: 25%;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff !important;
}

.room_description.flt {
  padding: 10px 0px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  color: #2d3436;
}

.carousel .thumbs-wrapper {
  margin: 0px !important;
}

.modal-header {
  padding: 0px 20px !important;
  justify-content: flex-end !important;
}

.description {
  text-align: justify;
  padding: 10px 0px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #2d3436;
  word-break: break-word;
}

.main_leftside.flt {
  padding: 30px 20px;
}

.main_row_pad.flt {
  padding: 20px 0px;
}

.checkin.flt {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}

.checkout {
  padding: 0px 10px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #002c60;
}

.main_checkin.flt {
  padding: 30px 15px 20px 15px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(0 108 207 / 20%);
  border-radius: 4px;
}

.check_aprl {
  text-align: center;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #ecb200;
}

/* .main_cla {
    padding-top: 20px;
} */

/* .main_cal_image.flt {

    padding: 20px 0px;
} */

.main_check ul {
  padding: 0px;
  margin: 0px;
}

.main_check li {
  display: flex;
  align-items: center;
  list-style-type: none;
}

.check_box label {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #000000;
  padding-left: 8px;
  text-transform: capitalize;
}

.check_box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.main_services.flt {
  padding: 22px 15px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(0 108 207 / 20%);
  border-radius: 4px;
}

.extra_ser.flt {
  padding: 10px 0px;
}

.services {
  margin-bottom: 20px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #000000;
}

.parking_money {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #000000;
}

.main_det.flt {
  padding-top: 10px;
}

.main_tnum {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 200;
  font-size: 41.9703px;
  line-height: 51px;
  color: #ffab29;
}

.main_cal_image.flt {
  position: relative;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

/* .main_che_pad {
    padding: 5px;
    border-radius: 2px;
} */

.guest_name {
  text-align: center;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #a00028;
}

.guest {
  background: #ffdede;
  border-radius: 2px;
  padding: 15px;
}

.main_input input {
  text-align: center;
  background: none;
  border: none;
  outline: none;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  color: #a00028;
  width: 100%;
}

.guest_img {
  display: flex;
  align-items: center;
}

.main_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_adults span {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #a00028;
  padding: 0px 5px;
}

.main_adult span {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #a00028;
  padding: 0px 5px;
}

.guest1 {
  background: #eccdc2;
  border-radius: 2px;
  padding: 19px;
}

.guest_name1 {
  text-align: center;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #5d3a30;
}

.main_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_input1 input {
  width: 100%;
  text-align: center;
  background: none;
  border: none;
  outline: none;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 200;
  font-size: 41.9703px;
  line-height: 51px;
  color: #5d3a30;
}

.total_length {
  margin-top: 15px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #2d3436;
}
.child_pax {
  margin-top: 15px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #2d3436;
}
.child_paxs {
  margin-top: 15px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin-top: 115px;
  /* margin-left: 45px; */
  /* margin-inline-start: 120px; */
  line-height: 20px;
  color: #2d3436;
}
hr.line {
  background: #121212 !important;
  height: 2px !important;
  margin: 6px !important;
  opacity: none !important;
}

hr.line1 {
  background: #121212 !important;
  height: 2px !important;
  margin: 6px -5px !important;
}

ul.room_charge {
  padding: 0px 10px !important;
  padding: 0px;
  margin: 0px;
}

.room_charge li {
  padding: 8px 0px;
  display: flex;
  list-style-type: none;
  justify-content: space-between;
}

.charges2 {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #2d3436;
}
.charges_bold {
  font-weight: 600;
}
.total_amt.flt {
  padding: 0px 10px !important;
  padding: 0px;
  margin: 0px;
}

.total_amt li {
  padding: 10px 0px;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ammount_clr {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: #053469;
}
.ammount_clr_no {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #053469;
}

.main_check {
  padding: 0px 10px;
}

.main_continue {
  background: #d49e2c;
  box-shadow: 0px 0px 11.6571px rgb(0 108 207 / 10%);
  color: rgb(255, 255, 255);
  border: none;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  border-radius: 5px;
  padding: 15px 0px;
}
/* 
.main_continue:hover {
  background: #ffffff;
  color: #0a8349;
} */

.continue_btn.flt {
  margin-top: 18px;
}

.left_view_room li {
  list-style-type: none;
  padding: 0px 0px 5px 5px;
}

.react-datepicker-wrapper input {
  width: 100%;
  outline: none;
  border: none;
  background: none;
  color: #ffab29;
  font-size: 20px;
  padding-left: 10px;
}

.modal-content {
  position: absolute !important;
}

/* .main_row{
    position: relative !important;
} */
.carousel .carousel-status {
  visibility: hidden;
}

.carousel.carousel-slider .control-arrow {
  visibility: hidden;
}

.modalPop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1050;
  width: 100%;
  height: 100vh;
  transition: all 0.5s ease-in-out;
  top: 0;
  left: 0;
  overflow: auto;
}

ul.left_view_room li span {
  display: none;
}

.form-control {
  font-size: 12px !important;
  padding: 8px 1px !important;
  border: none !important;
  /* background-color:transparent !important; */
  color: #ecb200 !important;
  font-weight: 600 !important;
  outline: none !important;
  text-align: center;
}
.rdt .form-control {
  padding: 15px 5px 15px 35px !important;
  /* color: rgb(0, 0, 0) !important;
  font-weight: 500 !important; */
  color: #434343 !important;
  font-weight: 400 !important;
  text-align: left;
}
.rdt .form-control::placeholder {
  color: rgb(0, 0, 0) !important;
}

.rdtOpen .rdtPicker {
  display: block;
  left: -50px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
  background-color: none;
}

.form-control[readonly] {
  background-color: #ffffff !important;
  opacity: 1;
}

.rdt {
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

/* 
.main_imgcal {
    position: relative;
    z-index: 1;
    left: 20%;
    top: 0;
} */

.person_count {
  color: rgb(0, 0, 0);
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin: 0px 10px;
}

.main_adult_img {
  display: flex;
  align-items: center;
  width: 50%;
}

.main_increment {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
.main_increment select {
  width: 100%;
}

.main_adult_img {
  display: flex;
  align-items: center;
}

.decrement_button {
  background: #053469;
  border: 1px solid #053469;
  border-radius: 4px;
  color: #fff;
  /* width: 28px;
height: 28px; */
  width: 18%;
  display: flex;
  justify-content: center;
}

.increment_button {
  background: #053469;
  border: 1px solid #053469;
  border-radius: 4px;
  color: #fff;
  /* width: 28px;
height: 28px;  */
  width: 18%;
  display: flex;
  justify-content: center;
}

.main_increment input {
  /* width: 25%; */
  color: #053469;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  /* outline: none; */
  /* border: none; */
}

.guest_adults.flt {
  display: flex;
  align-items: center;
  background: #ffffff;
  /* border: 1px solid #afafaf;
  box-shadow: 0px 0px 4px rgba(5, 52, 105, 0.1); */
  border-radius: 2px;
  padding: 10px;
  margin: 10px 0px;
}
.main_guest_adults .guest_adults:last-child {
  margin-bottom: 20px;
}
.main_guest_adults .guest_adults:first-child {
  margin-top: 20px;
}
.decrement_button i {
  padding: 5px;
  font-size: 14px;
}

.increment_button i {
  padding: 5px;
  font-size: 14px;
}

.main_cal_image img {
  position: absolute;
  top: 16px;
  bottom: 0px;
  left: 10px;
  z-index: 1;
}

.react-datepicker-wrapper input {
  width: 100%;
  outline: none;
  border: none;
  background: none;
  color: #ffab29;
  font-size: 20px;
  padding-left: 10px;
}

.modal-content {
  position: absolute !important;
}

.main_row {
  position: relative !important;
}

.carousel .carousel-status {
  visibility: hidden;
}

.carousel.carousel-slider .control-arrow {
  visibility: hidden;
}

.modalPop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1050;
  width: 100%;
  height: 100vh;
  transition: all 0.5s ease-in-out;
  top: 0;
  left: 0;
  overflow: auto;
}

ul.left_view_room li span {
  display: none;
}

.form-control {
  font-size: 12px !important;
  padding: 8px 1px !important;
  border: none !important;
  /* background-color:transparent !important; */
  color: #ecb200 !important;
  font-weight: 600 !important;
  outline: none !important;
}

#shippingModal .modal-dialog {
  max-width: 500px;
  margin: 1.75rem auto;
}

#shippingModal .modal-dialog .modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

#shippingModal .modal-body {
  padding: 1rem;
}

#shippingModal .modal-header {
  padding: 0px 20px !important;
  justify-content: flex-end !important;
  border-bottom: 1px solid #dee2e6;
}
.text_right {
  text-align: right;
}

.room_rate span {
  color: #434343;
  font-weight: 400;
}

.standard_amenities li {
  list-style-type: none;
  float: left;
  width: 33.33%;
  margin-bottom: 10px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.standard_amenities img {
  margin-right: 10px;
}
.standard_amenities span {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #2d3436;
}
.main_increment select {
  border: 1px solid #ced4da;
  background-color: transparent;
  color: #434343;
  font-size: 13px;
  font-weight: 400;
  padding: 5px;
  outline: none;
}
.guest_pad .room_rate {
  font-weight: 600;
  font-size: 24px;
  line-height: 37px;
  color: #002c60;
}
.guest_pad .room_rate span {
  font-weight: 400;
  font-size: 22px;
}

@media (min-width: 640px) and (max-width: 767px) {
  .main_row .col-sm-8,
  .main_row .col-sm-4 {
    width: 100%;
  }
  .main_wifi1_det li {
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main_row .col-sm-8,
  .main_row .col-sm-4 {
    width: 100%;
  }
  .main_wifi1_det li {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .main_discount {
    margin: 15px 0px;
  }
  .right_view_room {
    float: left;
    width: 100%;
  }

  .right_view_room img {
    width: 100%;
    height: auto;
  }

  .left_view_room {
    width: 100%;
    justify-content: space-around;
  }
  .main_leftside {
    padding: 0px 15px !important;
  }
  .main_row_pad {
    padding: 0px 15px !important;
  }
  .rdtOpen .rdtPicker {
    display: block;
    left: -1px;
  }
}
.rdtDays tfoot {
  visibility: hidden;
}
/* 
.addroom_btn {
  margin-top: 10px;
} */
.addroom_btn button {
  background: #d49e2c;
  box-shadow: 0px 0px 11.6571px rgb(0 108 207 / 10%);
  color: rgb(255, 255, 255);
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
}

.room_remove {
  background-color: #d49e2c;
  color: rgb(255, 255, 255);
  border: none;
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 4px;
  margin-left: 5px;
}
.main_guest_adults {
  background-color: #d49e2c54;
  padding: 0px 15px;
  margin-top: 25px;
  border-radius: 2px;
}
.addroom_block {
  margin-top: 15px;
}
.guest_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.opacityadd {
  opacity: 0.5;
  pointer-events: none;
}

@media (min-width: 768px) {
  .standard_amenities {
    padding: 0px 12px !important;
  }
  .main_description {
    padding: 0px 12px;
  }
}
