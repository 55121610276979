@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.hotel.flt {
  /* font-family:   /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  color: #002653;
}

.international.flt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
}
.noevents {
  pointer-events: none;
  opacity: 0.5;
}
.right_date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 25px rgb(0 108 207 / 20%);
  padding: 15px;
  border-radius: 6px;
}

.right_date .rdt {
  border: none !important;
  margin-right: 10px;
  position: relative;
}

.right_date .rdt::before {
  /* content: "\f073"; */
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 18px;
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  color: #002C60;
  content: '';
  background-image: url('../../../assets/images/Cal_image.png');
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.right_date .rdt::after {
  content: "\f0d7";
  position: absolute;
  top: 12px;
  right: 15px;
  font-size: 18px;
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  color: #636E72;
}

.right_date .rdt .form-control {
  padding: 15px 5px 15px 45px !important;
}

.right_date .rdt input {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: rgba(67, 67, 67, 1) !important;
  border: 1px solid #D9D9D9 !important;
  border-radius: 4px;
}

.right_date .rdt input:focus {
  box-shadow: none;
}

.right_date .rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: rgba(0, 132, 35, 1);
}

.right_date .rdtPicker td.rdtToday:before {
  border-bottom: 7px solid rgba(0, 132, 35, 1);
}

.right_date button {
  background-color: #D49E2C;
  padding: 12px;
  border: none;
  outline: none;
  border-radius: 4px;
}

.airport {
  padding: 0px 10px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #2D3436;
}

.main_hotel.flt {
  padding: 20px 30px;
}

@media (max-width: 767px) {
  .hotel {
    margin-bottom: 10px;
  }

  .international {
    display: block !important;
    padding: 5px 0px 20px 0px !important;
    border-bottom: 1px solid rgb(222, 222, 222);
  }

  .airport {
    line-height: 20px;
    margin-bottom: 25px;
  }

  .right_date {
    display: block;
    margin-bottom: 5px;
  }

  .right_date .rdt {
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .right_date button {
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .international .airport {
    width: 35%;
  }

  .international .right_date {
    width: 65%;
  }
}
