@import url('https://fonts.googleapis.com/css2?family=K2D:wght@100;200;300;400;500;600;700;800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=K2D:wght@100;200;300;400;500;600;700;800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=K2D:wght@100;200;300;400;500;600;700;800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

body {
  font-family: 'Open Sans', sans-serif !important;
}

.flt {
  float: left;
  width: 100%;
}

.filler {
  flex-grow: 1;
}

.modal-header button {
  border: none;
  background: white;
  border-radius: 2px solid black;
  font-size: 23px;
}

.main_load {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgb(255 255 255 / 90%);
}

.main_load img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}

.login_ip {
  position: relative;
}

.login_ip input {
  padding-left: 84px;
}

.input_details_login .login_ip span {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-35%);
  border-right: 1px solid rgba(118, 118, 118, 1);
  padding: 5px 20px;
  /* font-family: 'FuturaNormal'; */
  color: rgba(33, 33, 33, 1);
}

.login_ip span {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  border-right: 1px solid rgba(118, 118, 118, 1);
  padding: 5px 20px;
  /* font-family: 'FuturaNormal'; */
  font-family: 'Open Sans', sans-serif;
  color: rgba(33, 33, 33, 1);
}




/* Room Booking strat */


/* Room Booking end */


ul {
    margin: 0px !important;
    padding: 0px !important;
}


/* mobile view start */

@media screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .main_room_image.flt {
    margin: 10px 0px !important;
  }

  .che_pad.flt {
    margin: 10px 0px !important;
  }

  .main_guest.flt {
    margin: 10px 0px !important;
  }

  .view_room {
    margin: 0px 0px ! important;
    width: 50% !important;
  }

  /* .agile {
    display: block !important;

  } */

  .main_location.flt {
    margin: 7px 0px;
  }

  .loction_image {
    margin: 0px !important;
  }

  .main_adults.flt {
    padding-left: 40px !important;
    text-align: center;
  }

  .main_adult.flt {
    text-align: center;
  }

  .main_input1 {
    display: flex;
    justify-content: center;
  }

  .main_view.flt {

    display: block !important;
  }

  .left_view_room {
    padding: 10px 0px !important;
    flex-direction: row !important;
    justify-content: space-between;
  }

  .main_row {
    padding: 0px 0px !important;
  }


  /* .main_hotel.flt {
    padding: 20px 50px !important;
  } */

  .react-datepicker-wrapper input {
    padding-left: 70px !important;
  }

  /* .choose_room.flt {
    padding: 0px 10px !important;
  } */

  .left_view_room li {
    padding: 0px !important;
  }

  /* .booking_info {
  margin: 10px 0px;
} */


}

@media (max-width:767px){
      .main_hotel {
        padding: 15px 15px 10px 15px !important;
    }

    .agile {
      padding: 10px !important;
    }

    .checkin {
      padding: 10px !important;
    }
  .main_choose {
    padding: 0px 15px;
  }

.choose_room {
  font-size: 20px !important;
  padding: 0px !important;
}

.main_wifi1_det {
  min-height: 0px !important;
}
.main_row {
    padding: 0px 15px !important;
}
}

/* mobile view end */