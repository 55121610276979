@import url(https://fonts.googleapis.com/css2?family=K2D:wght@100;200;300;400;500;600;700;800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=K2D:wght@100;200;300;400;500;600;700;800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=K2D:wght@100;200;300;400;500;600;700;800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Inter:wght@300;400;500&family=Montserrat:wght@300&family=Playfair+Display:wght@400;600&family=Poppins:wght@300;400&family=Roboto:wght@100;300&family=Rochester&family=Saira:wght@200;300;400&family=Teko:wght@300;400;500&family=Titillium+Web:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.invalid-feedback{
  display: block !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.alert {
    position: fixed !important;
    bottom: 10px;
    float: right;
    right: 10px;
    z-index: 9;
        box-shadow: 0px 0px 8px 0px;
}


body {
  font-family: 'Open Sans', sans-serif !important;
}

.flt {
  float: left;
  width: 100%;
}

.filler {
  flex-grow: 1;
}

.modal-header button {
  border: none;
  background: white;
  border-radius: 2px solid black;
  font-size: 23px;
}

.main_load {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgb(255 255 255 / 90%);
}

.main_load img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}

.login_ip {
  position: relative;
}

.login_ip input {
  padding-left: 84px;
}

.input_details_login .login_ip span {
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-35%);
          transform: translateY(-35%);
  border-right: 1px solid rgba(118, 118, 118, 1);
  padding: 5px 20px;
  /* font-family: 'FuturaNormal'; */
  color: rgba(33, 33, 33, 1);
}

.login_ip span {
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-right: 1px solid rgba(118, 118, 118, 1);
  padding: 5px 20px;
  /* font-family: 'FuturaNormal'; */
  font-family: 'Open Sans', sans-serif;
  color: rgba(33, 33, 33, 1);
}




/* Room Booking strat */


/* Room Booking end */


ul {
    margin: 0px !important;
    padding: 0px !important;
}


/* mobile view start */

@media screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .main_room_image.flt {
    margin: 10px 0px !important;
  }

  .che_pad.flt {
    margin: 10px 0px !important;
  }

  .main_guest.flt {
    margin: 10px 0px !important;
  }

  .view_room {
    margin: 0px 0px ! important;
    width: 50% !important;
  }

  /* .agile {
    display: block !important;

  } */

  .main_location.flt {
    margin: 7px 0px;
  }

  .loction_image {
    margin: 0px !important;
  }

  .main_adults.flt {
    padding-left: 40px !important;
    text-align: center;
  }

  .main_adult.flt {
    text-align: center;
  }

  .main_input1 {
    display: flex;
    justify-content: center;
  }

  .main_view.flt {

    display: block !important;
  }

  .left_view_room {
    padding: 10px 0px !important;
    flex-direction: row !important;
    justify-content: space-between;
  }

  .main_row {
    padding: 0px 0px !important;
  }


  /* .main_hotel.flt {
    padding: 20px 50px !important;
  } */

  .react-datepicker-wrapper input {
    padding-left: 70px !important;
  }

  /* .choose_room.flt {
    padding: 0px 10px !important;
  } */

  .left_view_room li {
    padding: 0px !important;
  }

  /* .booking_info {
  margin: 10px 0px;
} */


}

@media (max-width:767px){
      .main_hotel {
        padding: 15px 15px 10px 15px !important;
    }

    .agile {
      padding: 10px !important;
    }

    .checkin {
      padding: 10px !important;
    }
  .main_choose {
    padding: 0px 15px;
  }

.choose_room {
  font-size: 20px !important;
  padding: 0px !important;
}

.main_wifi1_det {
  min-height: 0px !important;
}
.main_row {
    padding: 0px 15px !important;
}
}

/* mobile view end */
.disable_wifi {
  opacity: 0.25;
  pointer-events: none;
}

.main_wifi1_det {
  padding: 0px;
  margin: 0px;
  min-height: 102px;
}

.main_wifi1_det li {
  list-style-type: none;
  float: left;
  width: 33.33%;
  margin-bottom: 10px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.main_wifi1_det img {
  margin-right: 10px;
}

.main_wifi1_det span {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #2d3436;
}

.left_view_room li:last-child a {
  display: block;
}

.left_view_room li a {
  display: none;
}
.price_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: red;
  font-size: 10px;
}
.main_select {
  text-align: right;
}

.not_available_rooom {
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
}

@media (min-width: 640px) and (max-width: 767px) {
  .room_show .col-sm-3 {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .main_wifi1_det li {
    margin-bottom: 0px;
  }

  .hotel {
    font-size: 22px !important;
  }
  .main_wifi1_det li {
    width: 50%;
  }
  .price_flex {
    margin-top: 15px;
  }
  .select_room {
    padding: 10px 15px !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .room_show .col-sm-3 {
    width: 50%;
  }
}

/* TopBar start */

.Agile_text {
    font-family: 'K2D';
    font-style: normal;
    font-weight: 700;
    font-size: 13.38px;
    line-height: 17px;
    letter-spacing: 0.49em;
    text-transform: uppercase;
    color: #000000;
}

.hotel_book {
    font-family: 'K2D';
    font-style: normal;
    font-weight: 400;
    font-size: 7px;
    line-height: 8px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #000000;
}

.agile {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 108, 207, 0.2);
    display: flex;
    align-content: center;
    padding: 10px 30px;
}



.main_location.flt {
    display: flex;
    align-items: center;
    justify-content: flex-end;

}

.loction_image img {
    padding: 3px;
    border: 1px solid #B9B6EC;
    border-radius: 3px;
    background: #F5FAFF;
    margin-right: 10px;

}

.loction_image select {
    border: none;
    outline: none;
    background: none;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    color: #242040;
}

.search_bar.flt {
    width: 100%;
}

.loction_image {
    background: #F5FAFF;
    border: 1px solid #002856;
    border-radius: 4px;
    padding: 5px;
    margin: 0px 10px;


}

input[type="checkbox"]::before {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.main_agile_image {
    cursor: pointer;
}


.search {
    width: 90%;
    position: relative;
    display: flex;
    padding: 0px 8px;
}

.searchTerm {
    width: 100%;
    background: #F5FAFF;
    border: 1px solid #FFB94E;
    border-radius: 4px;
    height: 40px;
    outline: none;
    color: #9DBFAF;
}

.searchTerm:focus {
    color: #00B4CC;
}


.searchButton {
    position: absolute;
    background: #F5FAFF;
    border: 1px solid #FFD18A;
    border-radius: 3px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    margin: 2px;
    top: 5%;
    left: 85%;
    padding: 0px
}

.search img {
    padding: 5px;
}

.main_login {
    display: flex;
    align-items: center;

}

.main_login {
    display: flex;
    align-items: center;
}

.user_login {
    display: flex;
    align-items: center;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 0px 4px rgb(5 52 105 / 10%);
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    padding: 10px;
}

.log_in {
    /* 
    font-family: 'Raleway'; */
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    padding: 0px 20px;
    color: #2D3436;
}


.choose_room.flt {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #002C60;
    padding: 0px 30px 25px 30px;
}

.main_row {
    padding: 0px 30px;
}

.room_image.flt {
    text-align: center;
}

.main_standerd.flt {
    text-transform: capitalize;
/* font-family: "Inter", sans-serif; */
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #2D3436;
    text-align: left;
    padding: 20px 0px 15px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.bed_name.flt {
/* font-family: "Inter", sans-serif; */
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
}

.king_bed {
    display: flex;
    align-items: center;

}

.main_king_bed {
    display: flex;
    align-items: center;
    padding: 15px 0px;
    justify-content: space-between;
}

.main_room_image.flt {
    margin-bottom: 20px;
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 108, 207, 0.2);
    border-radius: 2px;
}

.king_bed img {
    padding: 0px 2px;
}

.room_image img {
    width: 100%;
    height: 200px;
}

.wifi {
/* font-family: "Inter", sans-serif; */
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #545454;
    padding: 0px 7px;
}

.main_wifi {
    display: flex;
    align-items: center;
    width: 33%;
}

.main_television {
    display: flex;
    align-items: center;
    width: 33%;
}

.main_shower {
    display: flex;
    align-items: center;
    width: 33%;
}

.Main_wifi_det.flt {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
}


.rate {

/* font-family: "Inter", sans-serif; */
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    -webkit-text-decoration-line: line-through;
            text-decoration-line: line-through;
    color: #FF8888;
}

.discount.flt {
    padding: 5px 0px;
}

.room_rate {
    /* font-family: "Inter", sans-serif; */
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    /* font-weight: 600; */
    font-size: 18px;
    line-height: 24px;
    color: #002C60;
    text-align: right;
    
}
.room_rates{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 26px;
    line-height: 37px;
    color: #002C60;
}

.room_per {
/* font-family: "Inter", sans-serif; */
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #282828;
}

.main_room_per.flt {
    padding: 8px 0px;

}

.tax_fees {
/* font-family: "Inter", sans-serif; */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}

.select_room {
    background: #D49E2C;
    box-shadow: 0px 0px 11.6571px rgb(0 108 207 / 10%);
    border: none;
    color: rgb(255, 255, 255);
/* font-family: "Inter", sans-serif; */
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    border-radius: 5px;
    padding: 12px 25px;
}

/* .select_room:hover {
        color: #0A8349;
    background: #ffffff;
    border: 1px solid  #0A8349;
    box-shadow: 0px 0px 10px rgba(0, 108, 207, 0.1);
} */



.myaccount {
    position: relative;
}

.myaccount::before {
    content: ' ';
    position: absolute;
    top: -12px;
    left: -25px;
    background-color: rgb(78 72 67 / 45%);
    width: 1px;
    height: 55px;
}

.myaccount:hover ul {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    transition: all .5s ease-in-out;
    opacity: 1 !important;
    visibility: visible !important;
    cursor: pointer;
}

.myaccount button {
    /* background: #F5FAFF;
    padding: 0px; */
    text-align: left;
    font-size: 14px;
    /* border: none; */
    outline: none;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 0px 4px rgba(5, 52, 105, 0.1);
    border-radius: 4px;
    background-color: transparent;
    padding: 4px 6px;
}

/* 
.myaccount button img {
    border: 1px solid #053469;
    padding: 3px;
    border-radius: 50%;
} */

.myaccount button i {
    margin: 0px 5px;
}

.myaccount ul {
    margin: 0px;
    padding: 8px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 4px rgb(120 120 120 / 50%);
    border-top: 2px solid #D49E2C;
    border-radius: 4px;
    width: 140px;
    position: absolute;
    right: 0px;
    top: 35px;
    z-index: 9;
    -webkit-transform: translateY(70px);
    transform: translateY(70px);
    /* opacity: 0; */
    visibility: hidden;
    transition: .5s ease-in-out;
    /* z-index: 1; */
}

.myaccount li {
    list-style-type: none;
    background-color: #d49e2c54;
    padding: 5px 10px;
    font-size: 13px;
    position: relative;
    cursor: pointer;
}

.myaccount li:first-child {
    margin-bottom: 5px;
}

.myaccount li i {
    font-weight: 600;
    color: #2D3436;
    position: absolute;
    right: 5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.myaccount li span {
    color: #2D3436;
}

.myaccount li img {
    margin-right: 3px;
}

/* TopBar end */

@media (max-width: 767px) {

    .home_row {
        padding: 0px !important;
    }

}
.hotel.flt {
  /* font-family:   /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  color: #002653;
}

.international.flt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
}
.noevents {
  pointer-events: none;
  opacity: 0.5;
}
.right_date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 25px rgb(0 108 207 / 20%);
  padding: 15px;
  border-radius: 6px;
}

.right_date .rdt {
  border: none !important;
  margin-right: 10px;
  position: relative;
}

.right_date .rdt::before {
  /* content: "\f073"; */
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 18px;
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  color: #002C60;
  content: '';
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGCSURBVHgBvZJNSwJRFIbvqKAW1SzSds0oIW2UIYjaNT/BZbYQjSiJooE2tao2RmhQiwolyIjWIhSBGxM/iAwbXAiV4BRSWX6MFdSitDvSWBjNMJuezT3nvZyXc869AHxxmcnYll2uLBBh0+PNBoNBG58jMCFCsfhCrVolItEYOjjQTwsZXN/kyBa1itZoOtkeDLMr/AeH/ovM1bHVYqGzDEPqdbqwkEHl6YXoNRiYYrEIIienIWCfcNQgZDqdNm95t3eACL69fX8icU5yNaOwVsGJdCplvs/naRzvDh/9mO8PAoXyI36WTGL1HXAdqJRKBkhErW5FWbaENkYAEuFHkPGCc8VFbXg8i/ACnZ2bD8ETE9L4OgUftLWjdJ+JqMd6TLcLj4qYxiH7dnpnSqUHFkEQtgo+GDHtl8Hz6xtVYFkbbBPP3d5xz9khpDVa+PclNhtJXmKzATI2OVWedoz7TEZjAEgA/sQh99o6hUSjccq56p7p0mpxKQZyuYK1jgwvfQLbYAUIzy3YxwAAAABJRU5ErkJggg==);
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.right_date .rdt::after {
  content: "\f0d7";
  position: absolute;
  top: 12px;
  right: 15px;
  font-size: 18px;
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  color: #636E72;
}

.right_date .rdt .form-control {
  padding: 15px 5px 15px 45px !important;
}

.right_date .rdt input {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: rgba(67, 67, 67, 1) !important;
  border: 1px solid #D9D9D9 !important;
  border-radius: 4px;
}

.right_date .rdt input:focus {
  box-shadow: none;
}

.right_date .rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: rgba(0, 132, 35, 1);
}

.right_date .rdtPicker td.rdtToday:before {
  border-bottom: 7px solid rgba(0, 132, 35, 1);
}

.right_date button {
  background-color: #D49E2C;
  padding: 12px;
  border: none;
  outline: none;
  border-radius: 4px;
}

.airport {
  padding: 0px 10px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #2D3436;
}

.main_hotel.flt {
  padding: 20px 30px;
}

@media (max-width: 767px) {
  .hotel {
    margin-bottom: 10px;
  }

  .international {
    display: block !important;
    padding: 5px 0px 20px 0px !important;
    border-bottom: 1px solid rgb(222, 222, 222);
  }

  .airport {
    line-height: 20px;
    margin-bottom: 25px;
  }

  .right_date {
    display: block;
    margin-bottom: 5px;
  }

  .right_date .rdt {
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .right_date button {
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .international .airport {
    width: 35%;
  }

  .international .right_date {
    width: 65%;
  }
}

.view_room {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #03438d;
  background: #ffffff;
  margin: 0px 5px;
  border: 1px solid #cee5ff;
  box-shadow: 0px 4px 10px rgba(0, 108, 207, 0.1);
  border-radius: 4px;
  width: 15%;
  height: 35px;
}

/* .main_increment input{
    pointer-events: none;
} */
.main_view_room.flt {
  text-align: end;
}

.table_container {
  margin: 20px 0px;
}

/* table {
  border-collapse: collapse;
  width: 80%;
  
}

th,
td {
  border: 1px solid #d49e2c;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #d49e2c;
  color: white;
  font-weight: bold;
}
tr:nth-child(even) {
  background-color: #c0b0b0;
}

tr:nth-child(odd) {
  background-color: #f2f2f2;
} */

.table {
  border-collapse: collapse;
  margin: auto;
  text-align: center;
}

.table-head {
  background-color: #d49e2c;
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.table-row:nth-child(even) {
  background-color: #c0b0b0;
  font-size: 16px;
}

.table-row:nth-child(odd) {
  background-color: #f2f2f2;
  font-size: 16px;
}

.table-header,
.table-data {
  padding: 8px;
}

.table-header {
  background-color: #d49e2c !important;
}

.table-data {
  font-weight: normal;
}

.view_room img {
  padding: 5px;
}

.room_stnd.flt {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 26.7941px;
  line-height: 32px;
  color: #002c60;
  padding: 15px 12px;
}

.main_standerd_rooom.flt {
  margin: 10px 0px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(0 108 207 / 20%);
  border-radius: 2px;
}
.main_Standerd_room {
  border-radius: 2px;
  box-shadow: 0px 4px 20px rgb(0 108 207 / 20%);
  border-radius: 2px;
  margin: 10px 0px;
}
.view_white {
  display: none;
}

.view_room:hover .view_blue {
  display: none !important;
}

.view_room:hover .view_white {
  display: inline-block !important;
}

.view_room:hover {
  background-color: #03438d;
  color: #fff;
}

.right_view_room img {
  width: 100%;
  /* padding: 5px 5px; */
  /* height: 400px; */
  /* object-fit: cover; */
}

.main_view.flt {
  display: flex;
  padding: 10px 10px;
}

.right_view_room {
  /* display: flex;
     */
  width: 85%;
}

.left_view_room {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
  width: 15%;
}

.left_view_room li {
  list-style-type: none;
}

/* .left_view_room img {
    padding: 0px 0px 5px 5px !important;
   
} */

.left_view_room li:last-child {
  padding-bottom: 0px !important;
}

.left_view_room li:last-child span {
  display: block;
  position: relative;
  text-align: center;
  bottom: 50%;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff !important;
}

.left_view_room li img {
  width: 100%;
  /* height: 77px; */
}

element.style {
  text-decoration: none;
}

.last_img {
  position: relative;
}

.last_img span {
  position: absolute;
  top: 33%;
  right: 25%;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff !important;
}

.room_description.flt {
  padding: 10px 0px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  color: #2d3436;
}

.carousel .thumbs-wrapper {
  margin: 0px !important;
}

.modal-header {
  padding: 0px 20px !important;
  justify-content: flex-end !important;
}

.description {
  text-align: justify;
  padding: 10px 0px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #2d3436;
  word-break: break-word;
}

.main_leftside.flt {
  padding: 30px 20px;
}

.main_row_pad.flt {
  padding: 20px 0px;
}

.checkin.flt {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}

.checkout {
  padding: 0px 10px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #002c60;
}

.main_checkin.flt {
  padding: 30px 15px 20px 15px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(0 108 207 / 20%);
  border-radius: 4px;
}

.check_aprl {
  text-align: center;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #ecb200;
}

/* .main_cla {
    padding-top: 20px;
} */

/* .main_cal_image.flt {

    padding: 20px 0px;
} */

.main_check ul {
  padding: 0px;
  margin: 0px;
}

.main_check li {
  display: flex;
  align-items: center;
  list-style-type: none;
}

.check_box label {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #000000;
  padding-left: 8px;
  text-transform: capitalize;
}

.check_box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.main_services.flt {
  padding: 22px 15px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(0 108 207 / 20%);
  border-radius: 4px;
}

.extra_ser.flt {
  padding: 10px 0px;
}

.services {
  margin-bottom: 20px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #000000;
}

.parking_money {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #000000;
}

.main_det.flt {
  padding-top: 10px;
}

.main_tnum {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 200;
  font-size: 41.9703px;
  line-height: 51px;
  color: #ffab29;
}

.main_cal_image.flt {
  position: relative;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

/* .main_che_pad {
    padding: 5px;
    border-radius: 2px;
} */

.guest_name {
  text-align: center;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #a00028;
}

.guest {
  background: #ffdede;
  border-radius: 2px;
  padding: 15px;
}

.main_input input {
  text-align: center;
  background: none;
  border: none;
  outline: none;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  color: #a00028;
  width: 100%;
}

.guest_img {
  display: flex;
  align-items: center;
}

.main_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_adults span {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #a00028;
  padding: 0px 5px;
}

.main_adult span {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #a00028;
  padding: 0px 5px;
}

.guest1 {
  background: #eccdc2;
  border-radius: 2px;
  padding: 19px;
}

.guest_name1 {
  text-align: center;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #5d3a30;
}

.main_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_input1 input {
  width: 100%;
  text-align: center;
  background: none;
  border: none;
  outline: none;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 200;
  font-size: 41.9703px;
  line-height: 51px;
  color: #5d3a30;
}

.total_length {
  margin-top: 15px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #2d3436;
}
.child_pax {
  margin-top: 15px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #2d3436;
}
.child_paxs {
  margin-top: 15px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin-top: 115px;
  /* margin-left: 45px; */
  /* margin-inline-start: 120px; */
  line-height: 20px;
  color: #2d3436;
}
hr.line {
  background: #121212 !important;
  height: 2px !important;
  margin: 6px !important;
  opacity: none !important;
}

hr.line1 {
  background: #121212 !important;
  height: 2px !important;
  margin: 6px -5px !important;
}

ul.room_charge {
  padding: 0px 10px !important;
  padding: 0px;
  margin: 0px;
}

.room_charge li {
  padding: 8px 0px;
  display: flex;
  list-style-type: none;
  justify-content: space-between;
}

.charges2 {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #2d3436;
}
.charges_bold {
  font-weight: 600;
}
.total_amt.flt {
  padding: 0px 10px !important;
  padding: 0px;
  margin: 0px;
}

.total_amt li {
  padding: 10px 0px;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ammount_clr {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: #053469;
}
.ammount_clr_no {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #053469;
}

.main_check {
  padding: 0px 10px;
}

.main_continue {
  background: #d49e2c;
  box-shadow: 0px 0px 11.6571px rgb(0 108 207 / 10%);
  color: rgb(255, 255, 255);
  border: none;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  border-radius: 5px;
  padding: 15px 0px;
}
/* 
.main_continue:hover {
  background: #ffffff;
  color: #0a8349;
} */

.continue_btn.flt {
  margin-top: 18px;
}

.left_view_room li {
  list-style-type: none;
  padding: 0px 0px 5px 5px;
}

.react-datepicker-wrapper input {
  width: 100%;
  outline: none;
  border: none;
  background: none;
  color: #ffab29;
  font-size: 20px;
  padding-left: 10px;
}

.modal-content {
  position: absolute !important;
}

/* .main_row{
    position: relative !important;
} */
.carousel .carousel-status {
  visibility: hidden;
}

.carousel.carousel-slider .control-arrow {
  visibility: hidden;
}

.modalPop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1050;
  width: 100%;
  height: 100vh;
  transition: all 0.5s ease-in-out;
  top: 0;
  left: 0;
  overflow: auto;
}

ul.left_view_room li span {
  display: none;
}

.form-control {
  font-size: 12px !important;
  padding: 8px 1px !important;
  border: none !important;
  /* background-color:transparent !important; */
  color: #ecb200 !important;
  font-weight: 600 !important;
  outline: none !important;
  text-align: center;
}
.rdt .form-control {
  padding: 15px 5px 15px 35px !important;
  /* color: rgb(0, 0, 0) !important;
  font-weight: 500 !important; */
  color: #434343 !important;
  font-weight: 400 !important;
  text-align: left;
}
.rdt .form-control::-webkit-input-placeholder {
  color: rgb(0, 0, 0) !important;
}
.rdt .form-control::placeholder {
  color: rgb(0, 0, 0) !important;
}

.rdtOpen .rdtPicker {
  display: block;
  left: -50px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
  background-color: none;
}

.form-control[readonly] {
  background-color: #ffffff !important;
  opacity: 1;
}

.rdt {
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

/* 
.main_imgcal {
    position: relative;
    z-index: 1;
    left: 20%;
    top: 0;
} */

.person_count {
  color: rgb(0, 0, 0);
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin: 0px 10px;
}

.main_adult_img {
  display: flex;
  align-items: center;
  width: 50%;
}

.main_increment {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
.main_increment select {
  width: 100%;
}

.main_adult_img {
  display: flex;
  align-items: center;
}

.decrement_button {
  background: #053469;
  border: 1px solid #053469;
  border-radius: 4px;
  color: #fff;
  /* width: 28px;
height: 28px; */
  width: 18%;
  display: flex;
  justify-content: center;
}

.increment_button {
  background: #053469;
  border: 1px solid #053469;
  border-radius: 4px;
  color: #fff;
  /* width: 28px;
height: 28px;  */
  width: 18%;
  display: flex;
  justify-content: center;
}

.main_increment input {
  /* width: 25%; */
  color: #053469;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  /* outline: none; */
  /* border: none; */
}

.guest_adults.flt {
  display: flex;
  align-items: center;
  background: #ffffff;
  /* border: 1px solid #afafaf;
  box-shadow: 0px 0px 4px rgba(5, 52, 105, 0.1); */
  border-radius: 2px;
  padding: 10px;
  margin: 10px 0px;
}
.main_guest_adults .guest_adults:last-child {
  margin-bottom: 20px;
}
.main_guest_adults .guest_adults:first-child {
  margin-top: 20px;
}
.decrement_button i {
  padding: 5px;
  font-size: 14px;
}

.increment_button i {
  padding: 5px;
  font-size: 14px;
}

.main_cal_image img {
  position: absolute;
  top: 16px;
  bottom: 0px;
  left: 10px;
  z-index: 1;
}

.react-datepicker-wrapper input {
  width: 100%;
  outline: none;
  border: none;
  background: none;
  color: #ffab29;
  font-size: 20px;
  padding-left: 10px;
}

.modal-content {
  position: absolute !important;
}

.main_row {
  position: relative !important;
}

.carousel .carousel-status {
  visibility: hidden;
}

.carousel.carousel-slider .control-arrow {
  visibility: hidden;
}

.modalPop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1050;
  width: 100%;
  height: 100vh;
  transition: all 0.5s ease-in-out;
  top: 0;
  left: 0;
  overflow: auto;
}

ul.left_view_room li span {
  display: none;
}

.form-control {
  font-size: 12px !important;
  padding: 8px 1px !important;
  border: none !important;
  /* background-color:transparent !important; */
  color: #ecb200 !important;
  font-weight: 600 !important;
  outline: none !important;
}

#shippingModal .modal-dialog {
  max-width: 500px;
  margin: 1.75rem auto;
}

#shippingModal .modal-dialog .modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

#shippingModal .modal-body {
  padding: 1rem;
}

#shippingModal .modal-header {
  padding: 0px 20px !important;
  justify-content: flex-end !important;
  border-bottom: 1px solid #dee2e6;
}
.text_right {
  text-align: right;
}

.room_rate span {
  color: #434343;
  font-weight: 400;
}

.standard_amenities li {
  list-style-type: none;
  float: left;
  width: 33.33%;
  margin-bottom: 10px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.standard_amenities img {
  margin-right: 10px;
}
.standard_amenities span {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #2d3436;
}
.main_increment select {
  border: 1px solid #ced4da;
  background-color: transparent;
  color: #434343;
  font-size: 13px;
  font-weight: 400;
  padding: 5px;
  outline: none;
}
.guest_pad .room_rate {
  font-weight: 600;
  font-size: 24px;
  line-height: 37px;
  color: #002c60;
}
.guest_pad .room_rate span {
  font-weight: 400;
  font-size: 22px;
}

@media (min-width: 640px) and (max-width: 767px) {
  .main_row .col-sm-8,
  .main_row .col-sm-4 {
    width: 100%;
  }
  .main_wifi1_det li {
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main_row .col-sm-8,
  .main_row .col-sm-4 {
    width: 100%;
  }
  .main_wifi1_det li {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .main_discount {
    margin: 15px 0px;
  }
  .right_view_room {
    float: left;
    width: 100%;
  }

  .right_view_room img {
    width: 100%;
    height: auto;
  }

  .left_view_room {
    width: 100%;
    justify-content: space-around;
  }
  .main_leftside {
    padding: 0px 15px !important;
  }
  .main_row_pad {
    padding: 0px 15px !important;
  }
  .rdtOpen .rdtPicker {
    display: block;
    left: -1px;
  }
}
.rdtDays tfoot {
  visibility: hidden;
}
/* 
.addroom_btn {
  margin-top: 10px;
} */
.addroom_btn button {
  background: #d49e2c;
  box-shadow: 0px 0px 11.6571px rgb(0 108 207 / 10%);
  color: rgb(255, 255, 255);
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
}

.room_remove {
  background-color: #d49e2c;
  color: rgb(255, 255, 255);
  border: none;
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 4px;
  margin-left: 5px;
}
.main_guest_adults {
  background-color: #d49e2c54;
  padding: 0px 15px;
  margin-top: 25px;
  border-radius: 2px;
}
.addroom_block {
  margin-top: 15px;
}
.guest_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.opacityadd {
  opacity: 0.5;
  pointer-events: none;
}

@media (min-width: 768px) {
  .standard_amenities {
    padding: 0px 12px !important;
  }
  .main_description {
    padding: 0px 12px;
  }
}

.booking_info {
  box-shadow: 0px 4px 20px rgba(0, 108, 207, 0.2);
  border-radius: 2px;
  padding: 20px 15px;
}
.booking_box {
  background-image: url(../../static/media/booking-info-bg.a2d580b5.png);
  background-repeat: repeat;
  background-position: center;
}
.personal_info {
  box-shadow: 0px 4px 20px rgba(0, 108, 207, 0.2);
  border-radius: 2px;
}

.your_booking {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #000000;
  margin-bottom: 20px;
}

.main_booking {
  padding: 0px 30px;
}

.room {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #2d3436;
  margin-bottom: 12px;
  text-transform: capitalize;
}

.basic_details {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-size: 16px;
  color: #434343;
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;
}

.basic_price {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #053469;
  margin-bottom: 8px;
}

.guest_cont {
  width: 60%;
  font-weight: 600;
}

.guest_info {
  width: 40%;
  text-align: right;
  font-weight: 700;
}

.guest_infos {
  width: 40%;
  text-align: right;
}

.room1_img img {
  width: 100%;
}

.payable_price {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  color: #002c60;
}

.grand_total {
  border-top: 1px solid #082e59;
  border-bottom: 1px solid #082e59;
  padding: 20px 0px;
  margin-top: 10px;
}

.enter_text {
  /* font-family: "Inter", sans-serif; */
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: #2d3436;
  margin-left: 20px;
}

.namedata {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #2d3436;
  margin-top: 14px;
}

.namedata1 {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-size: 14px;
  color: #2d3436;
  font-weight: 600;
  margin-top: 14px;
  display: flex;
}

.input_details input {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #212121;
  padding: 20px;
  outline: none;
  margin-top: 8px;
  width: 100%;
  height: 56px;
}

.input_details_login input {
  background: #ffffff;
  border: 1px solid #ffb94e;
  border-radius: 2px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #212121;
  padding: 20px 20px 20px 85px;
  outline: none;
  margin-top: 8px;
  width: 100%;
  height: 56px;
}
.note {
  display: flex;
  align-items: center;
}

.note button {
  background-color: rgb(212 158 44);
  box-shadow: 0px 0px 10px rgb(0 108 207 / 10%);
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  color: rgb(255, 255, 255);
  border: none;
  width: 100%;
  padding: 10px;
  text-align: center;
  color: #000000;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  outline: none;
  height: 56px;
}

.Verfie button {
  background-color: rgb(212 158 44);
  box-shadow: 0px 0px 10px rgb(0 108 207 / 10%);
  border-radius: 4px;
  border: none;
  color: rgb(255, 255, 255);
  width: 100%;
  padding: 10px;
  text-align: center;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  outline: none;
  height: 56px;
}

/* .Verfie {
    margin-top: 34px;
} */

.input_names {
  margin: 23px 0px 36px;
}

.input_otp_details input {
  background: #ffffff;
  border: 1px solid #2d3436;
  border-radius: 2px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #212121;
  padding: 20px;
  outline: none;
  margin-top: 8px;
  width: 18%;
  text-align: center;
  height: 56px;
}

.input_otp_details {
  justify-content: space-between;
}

.otp_data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timer {
  color: #636e72;
}

.resend {
  text-decoration: underline;
}

.rdtDays tfoot {
  /* pointer-events: none; */
  /* visibility: hidden; */
}
.cost_details {
  padding: 0px 20px;
}
.input_otp_details {
  margin-bottom: 35px;
}
.booking_left {
  /* background-color: rgb(235 244 255); */
  background-color: #d49e2c54;
  padding: 12px;
}

.cost_details {
  padding: 40px 20px 0px 20px;
}

.guest_box {
  padding: 16px !important;
  margin-top: 10px !important;
  background-image: url(../../static/media/guest-box-bg.01987acb.png);
  background-repeat: repeat;
  background-position: center;
}
.guest_box li {
  list-style-type: none;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.guest_box li:last-child {
  margin-bottom: 0px;
}
.price_details_head {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: rgb(0 44 96);
  margin-bottom: 20px;
}
.price_details li {
  list-style-type: none;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  color: rgb(77 77 77);
  margin-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cancelation_policy {
  margin-bottom: 15px;

  font-weight: 700;
}

.cancelation_policy a,
.cancelation_policy a:hover {
  color: rgb(212 158 44);
}
@media (min-width: 320px) and (max-width: 479px) {
  .payable_price {
    font-size: 11px;
  }
}
@media (min-width: 480px) and (max-width: 639px) {
  .input_otp_details input {
    width: 14%;
    padding: 10px;
  }

  .namedata1 {
    font-size: 12px;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .room {
    font-size: 12px;
  }
  .booking_main .col-sm-5,
  .booking_main .col-sm-7 {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .booking_box {
    margin-bottom: 20px;
  }
  .guest_box li {
    font-size: 12px;
  }
  .cost_details {
    padding: 20px 0px 0px 0px;
  }
  .your_booking {
    font-size: 20px;
  }

  .basic_details {
    font-size: 10px;
  }

  .main_room_info {
    padding: 4px;
  }

  .basic_price {
    font-size: 10px;
  }

  .enter_text {
    font-size: 14px;
  }

  .namedata {
    font-size: 12px;
  }

  .input_details input {
    height: 40px;
  }

  .namedata1 {
    font-size: 8px;
  }

  .input_otp_details input {
    height: 40px;
    padding: 16px;
  }

  .note button {
    height: 40px;
    font-size: 10px;
  }

  .Verfie button {
    height: 40px;
    font-size: 10px;
  }
  .main_booking {
    padding: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .booking_main .col-sm-5,
  .booking_main .col-sm-7 {
    width: 100%;
  }
  .basic_details {
    font-size: 10px;
  }
  .basic_price {
    font-size: 10px;
  }
  .namedata {
    font-size: 12px;
  }
  .input_details input {
    height: 40px;
    font-size: 12px;
  }
  .input_details_login .login_ip span {
    padding: 5px 10px;
    font-size: 12px;
  }
  .input_details_login input {
    padding: 10px 10px 10px 60px;
  }
  .main_standerd_rooom {
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .booking_main .col-sm-5,
  .booking_main .col-sm-7 {
    width: 100%;
  }
  .namedata1 {
    font-size: 10px;
  }

  .input_details input {
    height: 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .input_otp_details input {
    padding: 0px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.invalid-mob {
  display: block !important;
}

.razorpay-container iframe {
    pointer-events: auto !important;
  }
  
.main_row_padding.flt {
    padding: 10%;
}

.login_image {
    text-align: center;
}

.main_leftside1 {
    /* padding: 144px 30px; */
    /* background: radial-gradient(48.94% 48.94% at 29.76% 50.08%, #124886 0%, #002856 100%); */
}

.login_image {
    text-align: center;
    /* padding: 15px 0px; */
}

.main_rightside1.flt {
    padding: 12px 65px;
}

.login_here {
    text-decoration: underline;
    text-align: right;
    font-size: 14px;
    padding-top: 4px;
    color: #2D3436;
    font-weight: 600;
    float: right;
    width: 100%;
    cursor: pointer;
}

.main_boxshadow.flt {
    box-shadow: 0px 4px 20px rgb(0 108 207 / 20%);
    background: #FFFFFF;
}

/* .colourside{
        background: radial-gradient(48.94% 48.94% at 29.76% 50.08%, #124886 0%, #002856 100%);
} */
.login_name.flt {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 21px;
    color: #2D3436;
    padding-top: 20px;
}

.login_image img {
    width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.invalid-mob {
    display: block !important;
}
.note button {
    background-color: rgb(212 158 44);
    box-shadow: 0px 0px 10px rgb(0 108 207 / 10%);
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color: rgb(255,255,255);
    border: none;
}
.login_ip input {
    border: 1px solid rgb(45 52 54);
    border-radius: 2px;
}

@media (max-width:767px){
    .main_leftside1{
        padding: 0px !important;
        height: 100%;
    }

    .main_rightside1 {
    padding: 15px !important;
}

.login_name {
    text-align: center;
    padding-top: 0px !important;
    font-size: 25px !important;
}

.input_names {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
}

.input_details_login input {
    padding: 10px 10px 10px 60px;
}

.input_details_login .login_ip span {
    padding: 5px 10px;
}

.main_row_padding {
    padding: 10% 5% !important;
}


}

@media (min-width: 768px) and (max-width: 991px) {
    /* .main_leftside1 {
    padding: 165px 30px;
    } */

    .main_rightside1 {
    padding: 25px 15px 25px 0px !important;
}

.login_name {
    font-size: 25px !important;
    padding-top: 0px !important;
}


}

@media (min-width: 992px) and (max-width: 1199px) {
    .main_rightside1 {
    padding: 12px 15px 12px 0px !important;
}
}
.my_profile {
    padding: 20px 40px;
}

.profile_img {
    text-align: center;
}

.profile_cont {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 21px;
    color: #2D3436;
    padding: 22px 0px;
}

.profile_div {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(0 108 207 / 20%);
    border-radius: 2px;
    padding: 30px 30px;
}

.mob_numb {
    padding-bottom: 20px;
}

.mob_numb label {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #2D3436;
    padding-bottom: 8px;
}

.mob_numb input {
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #212121;
    background: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px rgb(0 108 207 / 10%);
    border-radius: 2px;
    padding: 20px 20px 20px 85px;
}

.full_name {
    padding-bottom: 20px;
}

.full_name label {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #2D3436;
    padding-bottom: 8px;
}

.full_name input {
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #212121;
    background: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px rgba(0, 108, 207, 0.1);
    border-radius: 2px;
    padding: 20px;
    outline: none;
}

.full_name input:focus {
    border: 1px solid rgba(255, 185, 78, 1);
    transition: 0.3s all ease-in-out;
}


.email_addres {
    padding-bottom: 20px;
}

.email_addres label {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #2D3436;
    padding-bottom: 8px;
}

.email_addres input {
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #212121;
    background: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px rgba(0, 108, 207, 0.1);
    border-radius: 2px;
    padding: 20px;
    outline: none;
}

.email_addres input:focus {
    border: 1px solid rgba(255, 185, 78, 1);
    transition: 0.3s all ease-in-out;
}

.profile_button {
    text-align: center;
    border-radius: 5px;
    margin: 26px 0px;
}

.profile_button button {
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    background: #D49E2C;
    box-shadow: 0px 0px 11.6571px rgb(0 108 207 / 10%);
    color: rgb(255, 255, 255);
    border: none;
    padding: 16px;
    font-size: 14px;
    border-radius: 4px;
}

.pre_booking {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 21px;
    color: #2D3436;
    padding-bottom: 28px;
}

.booking_ing {
    text-align: center;
}

.right_profile {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(0 108 207 / 20%);
    border-radius: 2px;
    padding: 30px 30px;
}

.booking_backgrnd {
    background: #d49e2c54;
    border-radius: 4px;
    padding: 60px 0px;
}

.booking_histry {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #2D3436;
    padding: 36px 0px;

}

.booking_button {
    text-align: center;
}

.booking_button button {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    color: #ffffff;
    background: #D49E2C;
    box-shadow: 0px 0px 12px rgb(0 108 207 / 10%);
    color: rgb(255, 255, 255);
    border-radius: 4px;
    padding: 12px 60px;
    border: none;
    text-transform: uppercase;
}
/* .booking_button button:hover {
    color: #0A8349;
    background-color: transparent;
} */
.stand_room {
    padding: 40px 28px;
}

.myroomdetails {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 21px;
    color: #2D3436;
}

.roomtitle {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #2D3436;
    padding: 12px 0px;
    text-transform: capitalize;
}

.mybook_img img {
    width: 100%;
}

.mybook_list {
    padding: 0px;
    list-style: none;
}

.mybook_list li:nth-child(even) {
    color: #2D3436;
    font-weight: 700;
}

.mybook_list li {
    float: left;
    width: 50%;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #2D3436;
    margin-bottom: 10px;
    font-weight: 600;
}

.booking_confirmed {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #2D3436;
    list-style: none;
    line-height: 24px;
}

.booking_confirmed :last-child {
    color: #0BA856;
    font-weight: 500;
    font-size: 11px;
}

.booking_confirmed :last-child i {
    font-weight: 600;
}

.seperate_room {
    background-color: #d49e2c54;
    margin-bottom: 20px;
    padding: 14px;
    border-radius: 4px;
}

.seperate_roomdark {
    background-color: #F9F9F9;
    margin-bottom: 20px;
    padding: 14px;
    border-radius: 4px;
}

.seperate_room .col-sm-2 {
    align-items: center;
    display: flex;
}

.myroom_top11 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.mr_viewall button {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    background: #D49E2C;
    box-shadow: 0px 0px 11.6571px rgb(0 108 207 / 10%);
    border-radius: 4px;
    padding: 15px 50px;
    border: none;
}

/* @media (min-width: 640px) and (max-width: 767px) {
.main_room_image {
        min-height: 392px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
} */


@media (max-width:767px) {
    .room_image img {
    width: 100%;
    height: 180px;
}
.booking_ing img {
    width: 100%;
}

    .stand_room {
        padding: 40px 0px;
    }

    .booking_confirmed {
        padding: 0px;

        line-height: 34px;

    }

    .mob_numb input {
        box-shadow: 0px 0px 10px rgb(0 108 207 / 20%);
        padding: 20px 20px 20px 60px;
    }

    .login_ip span {
        padding: 5px 10px;
    }

    .my_profile {
    padding: 20px;
}

.profile_div {
    padding: 15px;
}

.profile_cont {
    font-size: 25px;
    padding: 15px 0px;
}

.full_name input {
    box-shadow: 0px 0px 10px rgb(0 108 207 / 20%);
}
.email_addres input{
    box-shadow: 0px 0px 10px rgb(0 108 207 / 20%);
}
.mr_viewall button{
    font-size: 12px;
    padding: 6px 20px;
}
.myroomdetails{
    font-size: 20px;
}
.mybook_listdark li{
    font-size: 12px !important;
}
.booking_confirmeddark{
     font-size: 12px !important;
       background-color: rgb(222 222 222 / 21%);
    padding: 10px !important;
}
.seperate_roomdark{
    padding: 6px 12px;
}
}

.roomtitledark {
    color: #939393 !important;
}

.mybook_listdark {
    list-style: none;
}

.mybook_listdark li {
    float: left;
    width: 50%;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #939393 !important;
    margin-bottom: 10px;
    font-weight: 600;
}

.booking_confirmeddark {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #939393 !important;
    list-style: none;
    line-height: 24px;
}

.booking_confirmeddark :last-child {
    color: #939393 !important;
    font-weight: 500;
    font-size: 11px;
}


.pagination {
    justify-content: flex-end;
    margin-top: 40px !important;
}

.pagination li {
    margin-left: 10px;
}

.pagination li a {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    line-height: 25px;
    display: block;
    border:none;
    text-align: center;
    font-size: 11px;
}

.pagination li:first-child a,
.pagination li:last-child a {
    width: auto;
    height: auto;
    border: none;
    text-transform: uppercase;
        color:#2D3436;
    text-decoration: none;
    font-weight: 500;
}

.pagination li.active a {
background-color: #D49E2C;
    color: rgb(255, 255, 255) !important;
}

.thank_umain {
    background-image: url(../../static/media/thankubackgrnd.c9343381.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 0px;
    height: 100vh;

}

.thanku_img {
    text-align: center;
    padding: 27px 0px;
}

.thanku_booking {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 31px;
    /* line-height: 62px; */
    color: #01BFA5;
    margin-bottom: 20px;
}

.thanku_content {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 19px;
    line-height: 19px;
    text-align: center;
    color: #053469;
    padding-bottom: 36px;
}

.thanku_leftimg {
    text-align: center;
    background: #ffffff;
    border: 1px solid #636E72;
    box-shadow: 0px 0px 10px rgb(0 108 207 / 10%);
    border-radius: 7px;
    padding: 48px 20px;
}

.thanku_content a {
    color: #01BFA5;
}

.thanku_cont li {
    float: left;
    width: 50%;
    list-style-type: none;
    padding: 6px 0px;
}

.thanku_questions {
    text-align: center;
    padding: 40px 0px 40px 0px;
    color: #002C60;
    font-weight: 500;
}

.thanku_row {
    padding: 10px 15px;
}

.thanku_right {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #2D3436;
    background: #ffffff;
    border: 1px solid #636E72;
    box-shadow: 0px 0px 10px rgb(0 108 207 / 10%);
    border-radius: 7px;
    padding: 20px;
}

.thanku_cont li:nth-child(even) {
    text-align: right;
    font-weight: 700;
}

.thanku_cont li:nth-child(odd) {
    text-align: left;
}

.thanku_questions span {
    color: #01BFA5;
    text-decoration: underline;
}
@media (min-width: 320px) and (max-width: 479px) {
    .thanku_leftimg {
        margin-bottom: 20px;
    }
}
@media (min-width: 480px) and (max-width: 639px) {
    .thanku_leftimg {
        margin-bottom: 20px;
    }
}
@media (min-width: 640px) and (max-width: 767px) {
    .thanku_row .col-sm-2 {
        display: none;
    }
    .thanku_row .col-sm-3, .thanku_row .col-sm-5 {
        width: 50%;
    }
}
