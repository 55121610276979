@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.my_profile {
    padding: 20px 40px;
}

.profile_img {
    text-align: center;
}

.profile_cont {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 21px;
    color: #2D3436;
    padding: 22px 0px;
}

.profile_div {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(0 108 207 / 20%);
    border-radius: 2px;
    padding: 30px 30px;
}

.mob_numb {
    padding-bottom: 20px;
}

.mob_numb label {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #2D3436;
    padding-bottom: 8px;
}

.mob_numb input {
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #212121;
    background: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px rgb(0 108 207 / 10%);
    border-radius: 2px;
    padding: 20px 20px 20px 85px;
}

.full_name {
    padding-bottom: 20px;
}

.full_name label {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #2D3436;
    padding-bottom: 8px;
}

.full_name input {
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #212121;
    background: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px rgba(0, 108, 207, 0.1);
    border-radius: 2px;
    padding: 20px;
    outline: none;
}

.full_name input:focus {
    border: 1px solid rgba(255, 185, 78, 1);
    transition: 0.3s all ease-in-out;
}


.email_addres {
    padding-bottom: 20px;
}

.email_addres label {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #2D3436;
    padding-bottom: 8px;
}

.email_addres input {
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #212121;
    background: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px rgba(0, 108, 207, 0.1);
    border-radius: 2px;
    padding: 20px;
    outline: none;
}

.email_addres input:focus {
    border: 1px solid rgba(255, 185, 78, 1);
    transition: 0.3s all ease-in-out;
}

.profile_button {
    text-align: center;
    border-radius: 5px;
    margin: 26px 0px;
}

.profile_button button {
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    background: #D49E2C;
    box-shadow: 0px 0px 11.6571px rgb(0 108 207 / 10%);
    color: rgb(255, 255, 255);
    border: none;
    padding: 16px;
    font-size: 14px;
    border-radius: 4px;
}

.pre_booking {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 21px;
    color: #2D3436;
    padding-bottom: 28px;
}

.booking_ing {
    text-align: center;
}

.right_profile {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(0 108 207 / 20%);
    border-radius: 2px;
    padding: 30px 30px;
}

.booking_backgrnd {
    background: #d49e2c54;
    border-radius: 4px;
    padding: 60px 0px;
}

.booking_histry {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #2D3436;
    padding: 36px 0px;

}

.booking_button {
    text-align: center;
}

.booking_button button {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    color: #ffffff;
    background: #D49E2C;
    box-shadow: 0px 0px 12px rgb(0 108 207 / 10%);
    color: rgb(255, 255, 255);
    border-radius: 4px;
    padding: 12px 60px;
    border: none;
    text-transform: uppercase;
}
/* .booking_button button:hover {
    color: #0A8349;
    background-color: transparent;
} */
.stand_room {
    padding: 40px 28px;
}

.myroomdetails {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 21px;
    color: #2D3436;
}

.roomtitle {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #2D3436;
    padding: 12px 0px;
    text-transform: capitalize;
}

.mybook_img img {
    width: 100%;
}

.mybook_list {
    padding: 0px;
    list-style: none;
}

.mybook_list li:nth-child(even) {
    color: #2D3436;
    font-weight: 700;
}

.mybook_list li {
    float: left;
    width: 50%;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #2D3436;
    margin-bottom: 10px;
    font-weight: 600;
}

.booking_confirmed {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #2D3436;
    list-style: none;
    line-height: 24px;
}

.booking_confirmed :last-child {
    color: #0BA856;
    font-weight: 500;
    font-size: 11px;
}

.booking_confirmed :last-child i {
    font-weight: 600;
}

.seperate_room {
    background-color: #d49e2c54;
    margin-bottom: 20px;
    padding: 14px;
    border-radius: 4px;
}

.seperate_roomdark {
    background-color: #F9F9F9;
    margin-bottom: 20px;
    padding: 14px;
    border-radius: 4px;
}

.seperate_room .col-sm-2 {
    align-items: center;
    display: flex;
}

.myroom_top11 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.mr_viewall button {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    background: #D49E2C;
    box-shadow: 0px 0px 11.6571px rgb(0 108 207 / 10%);
    border-radius: 4px;
    padding: 15px 50px;
    border: none;
}

/* @media (min-width: 640px) and (max-width: 767px) {
.main_room_image {
        min-height: 392px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
} */


@media (max-width:767px) {
    .room_image img {
    width: 100%;
    height: 180px;
}
.booking_ing img {
    width: 100%;
}

    .stand_room {
        padding: 40px 0px;
    }

    .booking_confirmed {
        padding: 0px;

        line-height: 34px;

    }

    .mob_numb input {
        box-shadow: 0px 0px 10px rgb(0 108 207 / 20%);
        padding: 20px 20px 20px 60px;
    }

    .login_ip span {
        padding: 5px 10px;
    }

    .my_profile {
    padding: 20px;
}

.profile_div {
    padding: 15px;
}

.profile_cont {
    font-size: 25px;
    padding: 15px 0px;
}

.full_name input {
    box-shadow: 0px 0px 10px rgb(0 108 207 / 20%);
}
.email_addres input{
    box-shadow: 0px 0px 10px rgb(0 108 207 / 20%);
}
.mr_viewall button{
    font-size: 12px;
    padding: 6px 20px;
}
.myroomdetails{
    font-size: 20px;
}
.mybook_listdark li{
    font-size: 12px !important;
}
.booking_confirmeddark{
     font-size: 12px !important;
       background-color: rgb(222 222 222 / 21%);
    padding: 10px !important;
}
.seperate_roomdark{
    padding: 6px 12px;
}
}

.roomtitledark {
    color: #939393 !important;
}

.mybook_listdark {
    list-style: none;
}

.mybook_listdark li {
    float: left;
    width: 50%;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #939393 !important;
    margin-bottom: 10px;
    font-weight: 600;
}

.booking_confirmeddark {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #939393 !important;
    list-style: none;
    line-height: 24px;
}

.booking_confirmeddark :last-child {
    color: #939393 !important;
    font-weight: 500;
    font-size: 11px;
}


.pagination {
    justify-content: flex-end;
    margin-top: 40px !important;
}

.pagination li {
    margin-left: 10px;
}

.pagination li a {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    line-height: 25px;
    display: block;
    border:none;
    text-align: center;
    font-size: 11px;
}

.pagination li:first-child a,
.pagination li:last-child a {
    width: auto;
    height: auto;
    border: none;
    text-transform: uppercase;
        color:#2D3436;
    text-decoration: none;
    font-weight: 500;
}

.pagination li.active a {
background-color: #D49E2C;
    color: rgb(255, 255, 255) !important;
}
