.main_row_padding.flt {
    padding: 10%;
}

.login_image {
    text-align: center;
}

.main_leftside1 {
    /* padding: 144px 30px; */
    /* background: radial-gradient(48.94% 48.94% at 29.76% 50.08%, #124886 0%, #002856 100%); */
}

.login_image {
    text-align: center;
    /* padding: 15px 0px; */
}

.main_rightside1.flt {
    padding: 12px 65px;
}

.login_here {
    text-decoration: underline;
    text-align: right;
    font-size: 14px;
    padding-top: 4px;
    color: #2D3436;
    font-weight: 600;
    float: right;
    width: 100%;
    cursor: pointer;
}

.main_boxshadow.flt {
    box-shadow: 0px 4px 20px rgb(0 108 207 / 20%);
    background: #FFFFFF;
}

/* .colourside{
        background: radial-gradient(48.94% 48.94% at 29.76% 50.08%, #124886 0%, #002856 100%);
} */
.login_name.flt {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 21px;
    color: #2D3436;
    padding-top: 20px;
}

.login_image img {
    width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.invalid-mob {
    display: block !important;
}
.note button {
    background-color: rgb(212 158 44);
    box-shadow: 0px 0px 10px rgb(0 108 207 / 10%);
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color: rgb(255,255,255);
    border: none;
}
.login_ip input {
    border: 1px solid rgb(45 52 54);
    border-radius: 2px;
}

@media (max-width:767px){
    .main_leftside1{
        padding: 0px !important;
        height: 100%;
    }

    .main_rightside1 {
    padding: 15px !important;
}

.login_name {
    text-align: center;
    padding-top: 0px !important;
    font-size: 25px !important;
}

.input_names {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
}

.input_details_login input {
    padding: 10px 10px 10px 60px;
}

.input_details_login .login_ip span {
    padding: 5px 10px;
}

.main_row_padding {
    padding: 10% 5% !important;
}


}

@media (min-width: 768px) and (max-width: 991px) {
    /* .main_leftside1 {
    padding: 165px 30px;
    } */

    .main_rightside1 {
    padding: 25px 15px 25px 0px !important;
}

.login_name {
    font-size: 25px !important;
    padding-top: 0px !important;
}


}

@media (min-width: 992px) and (max-width: 1199px) {
    .main_rightside1 {
    padding: 12px 15px 12px 0px !important;
}
}